import React from 'react';

const SvgComponent = ({ svgRef, title, fill = `#e87e7c`, style, ...props }) => (
  <svg
    height="1em"
    viewBox="0 0 23.366 33"
    ref={svgRef}
    style={{
      transition: `fill 0.2s ease-out`,
      ...style
    }}
    {...props}
  >
    <title>{title || `Marker`}</title>
    <g data-name="Group 95">
      <path
        data-name="Path 200"
        d="M11.816 0A11.542 11.542 0 0 0 .266 11.55c0 8.662 11.55 21.45 11.55 21.45s11.55-12.788 11.55-21.45A11.542 11.542 0 0 0 11.816 0z"
        fill={fill}
      />
    </g>
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => (
  <SvgComponent svgRef={ref} {...props} />
));
export default ForwardRef;
