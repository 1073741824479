import GoogleMap from 'components/GoogleMap/index';
import { ParallaxChild, withParallaxProvider } from 'hooks/useParallax';
import React from 'react';
import { withTheme } from 'styled-components';
import { Section, Column, Container, Row } from 'system/layout';
import { Box } from 'system';
import * as A from 'system/animated';
import { animated } from 'react-spring';
import useWindowResize from 'hooks/useWindowResize';
import { withGlobals } from 'globals';
const CUT_HEIGHT = [150, 225, 350, 425];

function BG({ theme, scrollTop }) {
  const pts = scrollTop.to(st => {
    const t = Math.round(st * 0.1);
    const pos = t > 0 ? Math.min(t, 100) : 0;
    return `100,${pos} 100,100 0,100`;
  });

  const { documentHeight } = useWindowResize();

  return (
    <A.Absolute
      zIndex={0}
      top={[`100%`]}
      left={0}
      width={1}
      height={documentHeight > 0 ? documentHeight : 5000}
      display={`flex`}
      flexDirection={`column`}
    >
      <Box width={1} height={CUT_HEIGHT}>
        <Box
          as={`svg`}
          width={1}
          height={`100%`}
          viewBox={`0 0 100 100`}
          preserveAspectRatio={`none`}
        >
          <animated.polygon fill={theme.colors.white} points={pts} />
        </Box>
      </Box>
      <Box width={1} flex={1} bg={theme.colors.white} />
    </A.Absolute>
  );
}

function ContactMapBlock({
  theme,
  offices,
  selectedLocation,
  setSelectedLocation,
  ref,
  parallax,
  // stopScroll,
}) {
  return (
    <Section ref={ref} pt={0} mt={[-50, -100, -100]} mb={50}>
      <BG theme={theme} scrollTop={parallax.scrollTop} />
      <Container>
        <Row
          width={[`100vw`, `100vw`, 1]}
          justifyContent={[
            `flex-start`,
            `flex-start`,
            `center`,
            `center`,
            `center`,
          ]}
          ml={[`50%`, `50%`, 0, 0, 0]}
          mr={[0, 0, -3, -4, 0]}
          transform={[
            `translate3d(-50vw,0,0)`,
            `translate3d(-50vw,0,0)`,
            `none`,
            `none`,
            `none`,
          ]}
        >
          <ParallaxChild
            as={Column}
            offsetY={0.2}
            width={[1, 1, 10 / 12, 10 / 12, 1]}
            px={0}
            mb={[50, 50, 0]}
          >
            <GoogleMap
              location={selectedLocation ? selectedLocation.geolocation : null}
              markers={offices.map(office => ({
                lat: office.geolocation.latitude,
                lng: office.geolocation.longitude,
                office,
                onClick: selectedLocation
                  ? null
                  : () => {
                      // stopScroll();
                      setSelectedLocation({ ...office });
                    },
              }))}
            />
          </ParallaxChild>
        </Row>
      </Container>
    </Section>
  );
}

export default withGlobals(withTheme(withParallaxProvider(ContactMapBlock)));
