import GoogleMapReact from 'google-map-react';
import React, { useEffect, useRef, useState } from 'react';
import { withTheme } from 'styled-components';
import { Absolute as AnimatedAbsolute } from 'system/animated';
import { Box } from 'system';
import { useSpring } from 'react-spring';
import constants from 'utils/constants';
import smoothScrollTo from 'utils/scroll/smoothScrollTo';
import mapStyles from './mapStyles';
import Marker from './Marker';
import useIntersectionObserver from 'hooks/useIntersectionObserver';
// import Img from 'components/Img/index';

function GoogleMap({
  theme,
  zoom = 4,
  defaultCenter = { lat: 49.272150994800455, lng: -97.32770819829001 },
  location = null,
  markers = [],
}) {
  const dz =
    typeof window === `undefined` ? zoom : window.innerWidth < 768 ? 3 : zoom;
  const timeoutRef = useRef(0);
  const ref = useRef();
  const [inView] = useIntersectionObserver(ref, {
    rootMargin: `-40% 0% -40% 0%`,
    triggerOnce: true,
  });
  const [selectedMarker, setSelectedMarker] = useState(-1);

  const mapRef = useRef();

  const [currentLocation, setCurrentLocation] = useState({
    lat: defaultCenter.lat,
    lng: defaultCenter.lng,
    dz,
  });

  useEffect(() => {
    if (location) {
      smoothScrollTo(`map`, null, -100);
      setCurrentLocation({
        lat: location.latitude,
        lng: location.longitude,
        zoom: 13,
      });
    }
  }, [location]);

  useEffect(() => {
    return () => {
      clearTimeout(timeoutRef.current);
    };
  }, []);

  const [mapSpring, setMapSpring] = useSpring(() => ({
    opacity: 0,
  }));

  return (
    <Box
      ref={ref}
      id={`map`}
      as={`article`}
      width={1}
      pt={[`60%`, `60%`]}
      style={{ border: `1px solid ${theme.colors.blue}`, overflow: `hidden` }}
      bg={`#e8effb`} //color of water
    >
      {inView && (
        <AnimatedAbsolute
          top={0}
          left={0}
          width={1}
          height={`100%`}
          style={{ width: `100%`, height: `100%`, ...mapSpring }}
        >
          <GoogleMapReact
            ref={mapRef}
            style={{ width: `100%`, height: `100%` }}
            defaultZoom={3}
            zoom={currentLocation.zoom || dz}
            defaultCenter={{
              lat: defaultCenter.lat,
              lng: defaultCenter.lng,
            }}
            center={{
              lat: currentLocation.lat,
              lng: currentLocation.lng,
            }}
            options={{
              maxZoom: 17,
              minZoom: 2,
              styles: mapStyles,
            }}
            onGoogleApiLoaded={() => {
              timeoutRef.current = setTimeout(() => {
                setMapSpring({ opacity: 1 });
              }, 500);
            }}
            bootstrapURLKeys={{
              key: constants.GOOGLE_MAPS_API_KEY,
              libraries: ['places', 'geometry'],
            }}
            onChange={value => {
              // console.log(value);
            }}
            yesIWantToUseGoogleMapApiInternals
          >
            {markers.map((m, idx) => (
              <Marker
                index={idx}
                key={`marker-${idx}`}
                {...m}
                color={theme.colors.blue}
                selected={selectedMarker === idx}
                setSelectedMarker={setSelectedMarker}
                tipBg={theme.colors.grey}
              />
            ))}
          </GoogleMapReact>
        </AnimatedAbsolute>
      )}
    </Box>
  );
}

export default withTheme(GoogleMap);
