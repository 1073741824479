import MarkerSvg from 'components/icons/Marker';
import React from 'react';
import { animated, useSpring } from 'react-spring';
import styled from 'styled-components';
import { Absolute, Text } from 'system';

const StyledDiv = styled(animated.div)`
  position: absolute;
  top: 50%;
  left: 50%;
  cursor: ${({ onClick }) => (onClick ? 'pointer' : 'default')};
  overflow: visible;
  transform-origin: 50% 100%;

  &:hover {
    z-index: 2;
  }

  & figure,
  & span {
    position: relative;
    user-select: none;
  }

  & span {
    position: absolute;
    top: 50%;
    left: 50%;
    user-select: none;
    pointer-events: none;
    transform: translate3d(-50%, -12px, 0);
    line-height: 1;
  }
`;

const Marker = ({
  index,
  text,
  color,
  office,
  selected = false,
  onClick = null,
  setSelectedMarker,
  onMouseOver = null,
  onMouseOut = null,
  lat,
  lng,
  tipBg,
}) => {
  const animProps = useSpring({
    from: { transform: `translate3d(-50%, -50%, 0) scale(0)` },
    to: { transform: `translate3d(-50%, -50%, 0) scale(1)` },
  });

  const onMarkerClick = () => {
    setSelectedMarker(index);
  };

  return (
    <StyledDiv
      onClick={onClick || onMarkerClick}
      onMouseOver={onMarkerClick}
      onMouseOut={() => {
        setSelectedMarker(-1);
      }}
      style={{ ...animProps }}
      selected={selected}
    >
      <Text
        fill={color}
        title={text}
        as={MarkerSvg}
        fontSize={[24, 32, 36, 40]}
        lat={lat}
        lng={lng}
      />
      {selected && (
        <Absolute
          as={Text}
          bg={tipBg || `white`}
          bottom={`calc(100% + 10px)`}
          width={[200, 200, 250]}
          fontSize={(12, 14)}
          p={[8, 10, 12]}
          textAlign={`center`}
          transform={`translate3d(-52%,0,0)`}
          style={{
            borderRadius: 4,
            boxShadow: `0 2px 2px rgba(0, 0, 0, 0.3)`,
            zIndex: 1001,
          }}
          dangerouslySetInnerHTML={{
            __html: office.addressNode.childMarkdownRemark.html,
          }}
        />
      )}
    </StyledDiv>
  );
};

export default Marker;
