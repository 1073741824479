import React from 'react';
import { Box } from 'system';

const Plane = ({ title, ...props }) => (
  <Box {...props}>
    <svg width="100%" height="100%" viewBox="0 0 44.081 42.271">
      <title>{title}</title>
      <path
        d="M0 23.71l12.347 7.419 21.97-19.116-20.071 20.716v9.542l6.246-6.244 10.394 6.244L44.086 0z"
        fill="#001e45"
      />
    </svg>
  </Box>
);

export default Plane;
