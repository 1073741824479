import ContactLocationsBlock from 'blocks/ContactLocationsBlock';
import ContactMapBlock from 'blocks/ContactMapBlock';
import Footer from 'components/Footer';
import Plane from 'components/icons/Plane';
import {H2} from 'components/typography/headings';
import {graphql} from 'gatsby';
import {HelmetDatoCms} from 'gatsby-source-datocms';
import {withGlobals} from 'globals';
import React, {useEffect, useState} from 'react';
import {withTheme} from 'styled-components';
import {Column, Container, Row, Section} from 'system/layout';
import theme from 'theme';

function ContactPage({theme, data, footerData, setPageReady, setLogoColor}) {
	const {
		offices,
		contactCallout,
		contactCtaLabel,
		contactHeading,
	} = data.page;

	const [selectedLocation, setSelectedLocation] = useState(null);

	useEffect(() => {
		setLogoColor(theme.colors.blue);
		setPageReady(true);
	}, [theme.colors.blue, setLogoColor, setPageReady]);

	return (
		<>
			<HelmetDatoCms seo={data.page.seoMetaTags}/>
			<Section pt={[75, 100, 125, 150]}>
				<Container>
					<Row>
						<Column
							mt={40}
							mb={[10, 10, 20]}
							style={{display: `flex`, alignItems: `center`}}
							css={`
                              & h1 {
                                text-transform: uppercase;
                              }

                              .underline {
                                text-decoration: underline;
                              }
							`}
						>
							<H2
								as={`h1`}
								dangerouslySetInnerHTML={{__html: contactHeading}}
							/>

							<Plane
								style={{marginLeft: `20px`}}
								width={[35, 40, 45]}
								height={[35, 40, 45]}
							/>
						</Column>
					</Row>
					<Row>
						<Column width={1}>
							<ContactLocationsBlock
								data={{
									offices,
									contactCallout,
									contactCtaLabel,
								}}
								setSelectedLocation={setSelectedLocation}
							/>
						</Column>
					</Row>
				</Container>
			</Section>
			<ContactMapBlock
				selectedLocation={selectedLocation}
				setSelectedLocation={setSelectedLocation}
				offices={offices}
			/>
			<Footer data={footerData}/>
		</>
	);
}

export const query = graphql`
  query($locale: String!) {
    page: datoCmsContactPage(locale: { eq: $locale }) {
      id
      originalId
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      offices {
        id
        email
        contactName
        addressNode {
          childMarkdownRemark {
            html
          }
        }
        geolocation {
          latitude
          longitude
        }
        location
        phoneNumber
      }
      contactCallout
      contactCtaLabel
      contactHeading
    }
  }
`;

ContactPage.bgColor = theme.colors.grey;

export default withGlobals(withTheme(ContactPage));
