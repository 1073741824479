import LineHeading from 'components/LineHeading';
import PlusLink from 'components/PlusLink';
import { H4 } from 'components/typography/headings';
import { ParallaxContainer, ParallaxChild } from 'hooks/useParallax';
import React, { useMemo } from 'react';
import { withTheme } from 'styled-components';
import { Text } from 'system';
import { Column, Row } from 'system/layout';

function encodeEmail(s) {
  return s.replace(/[a-zA-Z]/g, function(c) {
    return String.fromCharCode(
      (c <= 'Z' ? 90 : 122) >= (c = c.charCodeAt(0) + 13) ? c : c - 26
    );
  });
}

function ContactLocationsBlock({
  data: { offices, contactCallout, contactCtaLabel },
  setSelectedLocation,
  theme,
}) {
  const emails = useMemo(() => {
    return offices.map(o => ({
      original: o.email,
      obfuscated: encodeEmail(o.email),
    }));
  }, [offices]);

  const gotoEmail = idx => {
    const email = emails[idx].original;
    window.location = `mailto:${email}`;
    return false;
  };

  return (
    <ParallaxContainer as={Row}>
      <ParallaxChild
        as={Column}
        width={[3 / 4, 3 / 4, 11 / 12]}
        ml={[`${(1 / 4) * 100}%`, `${(1 / 4) * 100}%`, `${(1 / 12) * 100}%`]}
        mb={40}
        mt={[40, 40, 50]}
        offsetY={`0.05`}
      >
        <LineHeading label={contactCallout} direction="rtl" />
      </ParallaxChild>
      {offices.map((office, idx) => (
        <ParallaxChild
          as={Column}
          offsetY={`0.1`}
          key={office.id}
          width={[1, 1, 5 / 12, 1 / 4]}
          ml={[0, 0, `${(1 / 12) * 100}%`]}
          mb={40}
        >
          <H4 color={theme.colors.blue}>{office.location}</H4>
          <Text
            mt={[15, 15, 25]}
            display={`block`}
            dangerouslySetInnerHTML={{
              __html: office.addressNode.childMarkdownRemark.html,
            }}
          />
          <Text display={`block`}>{office.phoneNumber}</Text>
          <Text mb={[15, 15, 25]} display={`block`}>
            <a
              href={`mailto: ${emails[idx].obfuscated}`}
              onClick={e => {
                e.preventDefault();
                gotoEmail(idx);
                return false;
              }}
              style={{
                color: `${theme.colors.blue}`,
                textDecoration: `none`,
                fontWeight: `bold`,
              }}
              css={`
                & span {
                  font-style: italic;
                  font-weight: normal;
                }
                :hover,
                :focus {
                  & span {
                    text-decoration: underline;
                  }
                }
              `}
            >
              Contact: <span>{office.contactName}</span>
            </a>
          </Text>
          <PlusLink
            label={contactCtaLabel}
            onClick={() => setSelectedLocation(office)}
            fontSize={[16, 18]}
          />
        </ParallaxChild>
      ))}
    </ParallaxContainer>
  );
}

export default withTheme(ContactLocationsBlock);
